<template>
  <div class="container">
    <div style="display: flex; justify-content: space-between; padding: 15px 0px 0 0">
      <h2 :class="settingsStore.colorScheme === 'dark' ? 'dark' : 'light'">Users list</h2>
      <a style="min-width: unset; width: 160px" :class="{'disabled': hasError}" class="btn-new"><font-awesome-icon style="font-size: 16px" icon="circle-plus" /> Add new <span></span></a>
    </div>
    <div class="deployments">
      <DataTable :data="usersTable.tableData"></DataTable>
      <ErrorComponent style="justify-self: center" @reload="async () => await fetchDeployments()" />
    </div>
  </div>
</template>

<script lang="ts">
import {defineComponent, reactive} from "vue";
import WIPComponent from "@/shared/WIPComponent.vue";
import DataTable from "@/shared/DataTable/DataTable.vue";
import {useSettings} from "@/stores/settings";

interface User {
  name: string;
  role: string;
  status: string;
  actions: Array<string>;
}

export default defineComponent({
  name: "UsersComponent",
  components: {
    DataTable
  },
  setup: () => {
    const settingsStore = useSettings()
    // Static mock data
    const usersTable = reactive({
      tableData: {
        tableConfig: {
          style: {
            columnWidth: ['10%', '25%','45%', '10%']
          }
        },
        sortKey: '',
        columns: [
          {label: 'Name', value: 'name', style: {'width': '10%'}},
          {label: 'Role', value: 'role', style: {'width': '25%'}},
          {label: 'Status', value: 'status', style: {'width': '45%'}},
          {label: 'Actions', value: 'actions', hasActions: true,style: {'width': '10%'}}
        ],
        rows: [
          {
            name: 'User 1',
            role: 'Domain administrator',
            status: 'Active',
            actions: [
              {
                icon: 'eye',
                onClick: () => {
                  return null
                },
              },
              {
                icon: 'pencil',
                onClick: () => {
                  return null
                },
              },
              {
                icon: 'trash',
                onClick: () => {
                  return null
                },
              }
            ]
          },
          {
            name: 'User 2',
            role: 'Domain administrator',
            status: 'Active',
            actions: [
              {
                icon: 'eye',
                onClick: () => {
                  return null
                },
              },
              {
                icon: 'pencil',
                onClick: () => {
                  return null
                },
              },
              {
                icon: 'trash',
                onClick: () => {
                  return null
                },
              }
            ]
          },
          {
            name: 'User 3',
            role: 'Domain administrator',
            status: 'Active',
            actions: [
              {
                icon: 'eye',
                onClick: () => {
                  return null
                },
              },
              {
                icon: 'pencil',
                onClick: () => {
                  return null
                },
              },
              {
                icon: 'trash',
                onClick: () => {
                  return null
                },
              }
            ]
          },
          {
            name: 'User 4',
            role: 'Domain administrator',
            status: 'Active',
            actions: [
              {
                icon: 'eye',
                onClick: () => {
                  return null
                },
              },
              {
                icon: 'pencil',
                onClick: () => {
                  return null
                },
              },
              {
                icon: 'trash',
                onClick: () => {
                  return null
                },
              }
            ]
          },
          {
            name: 'User 5',
            role: 'Domain administrator',
            status: 'Active',
            actions: [
              {
                icon: 'eye',
                onClick: () => {
                  return null
                },
              },
              {
                icon: 'pencil',
                onClick: () => {
                  return null
                },
              },
              {
                icon: 'trash',
                onClick: () => {
                  return null
                },
              }
            ]
          },
          {
            name: 'User 6',
            role: 'Domain administrator',
            status: 'Active',
            actions: [
              {
                icon: 'eye',
                onClick: () => {
                  return null
                },
              },
              {
                icon: 'pencil',
                onClick: () => {
                  return null
                },
              },
              {
                icon: 'trash',
                onClick: () => {
                  return null
                },
              }
            ]
          }
        ]
      }
    })
    return {
      settingsStore,
      usersTable
    }
  }
})
</script>

<style scoped>

h2 {
  color: #004AAD;
}

</style>