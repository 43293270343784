import {createI18n} from 'vue-i18n';

/**
 * I18n module
 * About vue-i18n: https://vue-i18n.intlify.dev/
 */

const i18n =  createI18n(Object.freeze({
    locale: localStorage.getItem('i18n') || 'en',
    fallbackLocale: 'en',
    messages: {
        en: {
            sidebar: {
                home: 'Home',
                domains: 'Domains',
                deployments: 'Deployments',
                notifications: 'Notifications',
                users: 'Users',
                settings: 'Settings'
            },
            home: {
              welcome: 'Welcome to the aerOS Management Portal',
              subtitle: 'This is the Management Portal, the single entrypoint to manage the aerOS Meta-OS'
            },
            domains: {
                domainsList: 'Domains list',
                description: 'Description',
                publicUrl: 'Public Url',
                owner: 'Owner',
                entrypoint: 'Entrypoint',
                status: 'Status',
                view: 'View',
                domainDetail: 'Domain detail',
                infrastructureElements: 'Infrastructure elements',
                metrics: 'Metrics'

            },
            deployments: {
                deployedServices: 'Deployed services',
                description: 'Description',
                name: 'Name',
                actions: 'Actions',
                newServiceDeployment: 'New service deployment',
                serviceDetail: 'Service detail',
                serviceComponents: 'Service components',
                containerImage: 'Container image',
                status: 'Status'
            },
            settings: {
                settings: 'Settings',
                generalSettings: 'General settings',
                systemSettings: 'System settings',
                darkMode: 'Dark mode',
                language: 'Language',
                fontSize: 'Font size',
                pushNotification: 'Push notification',
                metrics: 'IE metric visualization'
            },
            wip: {
                workInProgress: "Work in progress"
            }
        },
        it: {
            sidebar: {
                home: 'Home',
                domains: 'Domini',
                deployments: 'Istanze',
                notifications: 'Notifiche',
                users: 'Utenti',
                settings: 'Impostazioni'
            },
            home: {
                welcome: 'Benvenuto nel Portale di Gestione di aerOS',
                subtitle: 'Questo è il Portale di Gestione, il punto di accesso unico per gestire il Meta-OS di aerOS'
            },
            domains: {
                domainsList: 'Lista dei domini',
                description: 'Descrizione',
                publicUrl: 'Url pubblico',
                owner: 'Proprietario',
                entrypoint: 'Punto di ingresso',
                status: 'Stato',
                view: 'Visualizza',
                domainDetail: 'Dettaglio del dominio',
                infrastructureElements: "Elementi dell'infrastruttura",
                metrics: "Metriche"
            },
            deployments: {
                deployedServices: 'Istanze rilasciate',
                description: 'Descrizione',
                name: 'Nome',
                actions: 'Azioni',
                newServiceDeployment: 'Nuova istanza',
                serviceDetail: 'Dettaglio del servizio',
                serviceComponents: 'Componenti del servizio',
                containerImage: 'Immagine del container',
                status: 'Stato'
            },
            settings: {
                settings: 'Impostazioni',
                generalSettings: 'Impostazioni generali',
                systemSettings: 'Impostazioni di sistema',
                darkMode: 'Tema scuro',
                language: 'Lingua',
                fontSize: 'Dimensione font',
                pushNotification: 'Notifiche push',
                metrics: 'Visualizzazione metriche IE'
            },
            wip: {
                workInProgress: "Presto disponibile"
            }
        },
        es: {
            sidebar: {
                home: 'Home',
                domains: 'Dominios',
                deployments: 'Despliegues',
                notifications: 'Notificaciones',
                users: 'Usuarios',
                settings: 'Ajustes'
            },
            home: {
                welcome: 'Bienvenido al Portal de Gestión de aerOS',
                subtitle: 'Este es el Portal de Gestión, el único punto de entrada para gestionar el Meta-OS de aerOS'
            },
            domains: {
                domainsList: 'Lista de dominios',
                description: 'Descripción',
                publicUrl: 'Url público',
                owner: 'Propietario',
                entrypoint: 'Punto de entrada',
                status: 'Estado',
                view: 'Ver',
                domainDetail: 'Detalle del dominio',
                infrastructureElements: 'Elementos de infraestructura',
                metrics: 'Métricas'
            },
            deployments: {
                deployedServices: 'Servicios desplegados',
                description: 'Descripción',
                name: 'Nombre',
                actions: 'Acciones',
                newServiceDeployment: 'Nuevo despliegue de servicio',
                serviceDetail: 'Detalle del servicio',
                serviceComponents: 'Componentes del servicio',
                containerImage: 'Imagen del contenedor'
            },
            settings: {
                settings: 'Ajustes',
                generalSettings: 'Ajustes generales',
                systemSettings: 'Ajustes del sistema',
                darkMode: 'Modo oscuro',
                language: 'Idioma',
                fontSize: 'Tamaño de fuente',
                pushNotification: 'Notificaciones push',
                metrics: 'Visualización de métricas IE'
            },
            wip: {
                workInProgress: "Trabajo en progreso"
            }
        },
        fr: {
            sidebar: {
                home: 'Home',
                domains: 'Domaines',
                deployments: 'Déploiements',
                notifications: 'Notifications',
                users: 'Utilisateurs',
                settings: 'Paramètres'
            },
            home: {
                welcome: 'Bienvenue sur le Portail de Gestion de aerOS',
                subtitle: 'Ceci est le Portail de Gestion, le point d’entrée unique pour gérer le Meta-OS de aerOS'
            },
            domains: {
                domainsList: 'Liste des domaines',
                description: 'Description',
                publicUrl: 'Url public',
                owner: 'Propriétaire',
                entrypoint: 'Point d’entrée',
                status: 'Statut',
                view: 'Voir',
                domainDetail: 'Détail du domaine',
                infrastructureElements: "Éléments de l'infrastructure",
                metrics: 'Métriques'
            },
            deployments: {
                deployedServices: 'Services déployés',
                description: 'Description',
                name: 'Nom',
                actions: 'Actions',
                newServiceDeployment: 'Nouveau déploiement de service',
                serviceDetail: 'Détail du service',
                serviceComponents: 'Composants du service',
                containerImage: 'Image du conteneur'
            },
            settings: {
                settings: 'Paramètres',
                generalSettings: 'Paramètres généraux',
                systemSettings: 'Paramètres du système',
                darkMode: 'Mode sombre',
                language: 'Langue',
                fontSize: 'Taille de la police',
                pushNotification: 'Notifications push',
                metrics: 'Affichage des métriques IE'
            },
            wip: {
                workInProgress: "Travail en cours"
            }
        }
    }
}))

export default i18n