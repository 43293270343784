<template>
  <h2>{{ $t('wip.workInProgress') }} <font-awesome-icon :icon="['fa', 'screwdriver-wrench']" /></h2>
</template>

<script lang="ts">
import {defineComponent} from "vue";

export default defineComponent({
  name: "WIPComponent"
})
</script>

<style scoped>

</style>