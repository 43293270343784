import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, vShow as _vShow, withDirectives as _withDirectives, createVNode as _createVNode, Fragment as _Fragment, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-3f1aac90"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "container" }
const _hoisted_2 = { style: {"display":"flex","justify-content":"space-between","padding":"15px 0px 55px 0"} }
const _hoisted_3 = { class: "domains" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DataTable = _resolveComponent("DataTable")!
  const _component_ErrorComponent = _resolveComponent("ErrorComponent")!
  const _component_router_view = _resolveComponent("router-view")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _withDirectives(_createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("h2", {
          class: _normalizeClass(_ctx.settingsStore.colorScheme === 'dark' ? 'dark' : 'light')
        }, _toDisplayString(_ctx.$t('domains.domainsList')), 3)
      ]),
      _createElementVNode("div", _hoisted_3, [
        (!_ctx.hasError)
          ? (_openBlock(), _createBlock(_component_DataTable, {
              key: 0,
              data: _ctx.table.tableData
            }, null, 8, ["data"]))
          : _createCommentVNode("", true),
        (_ctx.hasError)
          ? (_openBlock(), _createBlock(_component_ErrorComponent, {
              key: 1,
              onReload: _cache[0] || (_cache[0] = async () => await _ctx.fetchDomains())
            }))
          : _createCommentVNode("", true)
      ])
    ], 512), [
      [_vShow, _ctx.$route.name === 'domains']
    ]),
    _createVNode(_component_router_view)
  ], 64))
}