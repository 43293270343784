<template>
  <div v-if="!loaderStore.loading" class="container">
    <div style="display: flex; justify-content: space-between; padding: 15px 0px 0 0">
      <h2 :class="settingsStore.colorScheme === 'dark' ? 'dark' : 'light'">{{$t('domains.domainDetail')}}</h2>
      <router-link to="/domains" class="back"><font-awesome-icon style="font-size: 16px" icon="arrow-left" /> {{$t('sidebar.domains')}} <span></span></router-link>
    </div>
    <div style="display: flex; justify-content: space-between;">
      <DataTable :data="domainIE"></DataTable>
    </div>
    <div v-if="!hasError" style="display: flex; justify-content: space-between; padding: 15px 0px 0 0">
      <h2 :class="settingsStore.colorScheme === 'dark' ? 'dark' : 'light'">{{$t('domains.infrastructureElements')}}:</h2>
    </div>
    <div style="display: flex; justify-content: space-between;">
      <DataTable :data="infrastructureElements"></DataTable>
    </div>
  </div>
  <ErrorComponent style="justify-self: center" v-if="hasError" @reload="async () => await fetchDomains()" />
</template>

<script lang="ts">
import {defineComponent, onMounted, reactive, ref} from "vue";
import {useDomainStore} from "@/stores/domain";
import {useLoaderStore} from "@/stores/loader";
import {useToastsStore} from "@/stores/toasts";
import DataTable from "@/shared/DataTable/DataTable.vue";
import ErrorComponent from "@/shared/ErrorComponent.vue";
import {useSettings} from "@/stores/settings";

export default defineComponent({
  name: "DomainDetail",
  components: {DataTable, ErrorComponent},
  props: ['id'],
  setup: (props) => {
    const domainStore = useDomainStore()
    const loaderStore = useLoaderStore()
    const toastStore = useToastsStore()
    const settingsStore = useSettings()
    const hasError = ref(false)
    const domainIE = reactive({
      sortKey: '',
      columns: [
        {label: 'Id', value: 'id', style: {'width': '14,2%'}},
        {label: 'domains.description', value: 'description', style: {'width': '14,2%'}},
        {label: 'domains.publicUrl', value: 'publicUrl', style: {'width': '14,2%'}},
        {label: 'domains.owner', value: 'owner', style: {'width': '14,2%'}},
        {label: 'domains.entrypoint', value: 'entrypoint', hasActions: true, style: {'width': '14,2%'}},
        {label: 'domains.status', value: 'status', style: {'width': '14,2%'}}
      ],
      rows: null as any
    })
    const infrastructureElements = reactive( {
      sortKey: '',
      columns: [
        {label: 'ID', value: 'id', style: {'width': '12,5%'}},
        {label: 'LLO', value: 'lowLevelOrchestrator', style: {'width': '12,5%'}},
        {label: 'CPU arch', value: 'cpuArchitecture', style: {'width': '12,5%'}},
        {label: 'CPU cores', value: 'cpuCores', style: {'width': '12,5%'}},
        {label: 'RAM capacity (MB)', value: 'ramCapacity', style: {'width': '12,5%'}},
        {label: 'Real time', value: 'realTimeCapable', hasActions: true, style: {'width': '12,5%'}},
        {label: 'domains.status', value: 'infrastructureElementStatus', style: {'width': '12,5%'}},
        {label: 'domains.metrics', value: 'actions', hasActions: true, style: {'width': '12,5%'}}
      ],
      rows: null as any
    })
    const domainId: string = props.id.split("&").length > 0 ? props.id.split("&")[0] : props.id
    const fetchDomains = async () => {
      domainIE.rows = await domainStore.fetchDomain(domainId)
          .then((res) => {
            if (res.data.length > 0) {
              res.data[0].visible = true;
            }
            const owners = res.data.owner.map((owner: string) => owner.split(":Organization:")[1])
            res.data.owner = owners.join()
            res.data.id = res.data.id.split(":Domain:")[1]
            res.data.status = res.data.domainStatus.split(":DomainStatus:")[1]
            res.data.entrypoint = res.data.entrypoint?  [{icon: 'check'}] : [{icon: 'xmark'}]
            res.data.actions = [{icon: 'pencil'}]
            hasError.value = false
            return [res.data]
          })
          .catch(err => {
            hasError.value = true
            console.error(err)
            toastStore.appendToast(
                {
                  label: `Looks like something went wrong: ${err?.message}`,
                  success: false,
                  data: {},
                  autoRemove: false
                })
          })

      infrastructureElements.rows = await domainStore.fetchIE(domainId)
          .then((res) => {
            res.data.map((d: any) => {
              d.id = d.id.split(":InfrastructureElement:")[1]
              d.lowLevelOrchestrator = d.lowLevelOrchestrator.split(":LowLevelOrchestrator:")[1]
              d.cpuArchitecture = d.cpuArchitecture.split(":CpuArchitecture:")[1]
              d.realTimeCapable = d.realTimeCapable ? [{icon: 'check', onClick: () => null}] : [{icon: 'xmark', onClick: () => null}]
              d.infrastructureElementStatus = d.infrastructureElementStatus.split(":InfrastructureElementStatus:")[1]
              d.actions = [
                {icon: 'chart-line', onClick: () => null}
              ]
            })
            hasError.value = false
            return res.data
          })
          .catch(err => {
            hasError.value = true
            console.error(err)
            toastStore.appendToast(
                {
                  label: `Looks like something went wrong: ${err?.message}`,
                  success: false,
                  data: {},
                  autoRemove: false
                })
          })
    }
    onMounted(async () => {
      await fetchDomains()
    })
    return {
      domainIE,
      infrastructureElements,
      loaderStore,
      hasError,
      settingsStore,
      fetchDomains
    }
  }
})
</script>

<style scoped>

.back {
  border-radius: 20px;
  background: #004AAD;
  color: #FFFFFF;
  min-width: 161px;
  height: 30px;
  padding: 3px;
  font-family: Poppins;
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: space-around;
  align-self: center;
  cursor: pointer;
  font-size: 14px;
  font-weight: bold;
  transition: opacity 0.2s ease-in-out;
}

.back:hover {
  opacity: 0.7;
}

h2 {
  color: #004AAD;
}

</style>