import { resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-e3b4aba8"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "error" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!

  return (_openBlock(), _createElementBlock("span", _hoisted_1, [
    _createTextVNode("Something went wrong, try to "),
    _createElementVNode("a", {
      class: "reload",
      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('reload')))
    }, [
      _createTextVNode("Reload "),
      _createVNode(_component_font_awesome_icon, { icon: ['fa', 'rotate'] })
    ])
  ]))
}