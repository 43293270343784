import * as VueRouter from 'vue-router'
import HomeComponent from "@/components/HomeComponent.vue";
import DomainsComponent from "@/components/DomainsComponent.vue";
import NotificationsComponent from "@/components/NotificationsComponent.vue";
import DeploymentsComponent from "@/components/DeploymentsComponent.vue";
import ContinuumComponent from "@/components/ContinuumComponent.vue";
import NewDeploymentComponent from "@/components/NewDeploymentComponent.vue";
import DomainDetail from "@/components/DomainDetail.vue";
import DeploymentDetailComponent from "@/components/DeploymentDetailComponent.vue";
import SettingsComponent from "@/components/SettingsComponent.vue";
import KeycloakService from "@/services/AuthenticationService";
import UsersComponent from "@/components/UsersComponent.vue";
import BenchmarkingToolComponent from "@/components/BenchmarkingToolComponent.vue";

const ROLES = Object.freeze({
    iotServiceDeployer: 'IoT service deployer',
    domainAdministrator: 'Domain administrator',
    continuumAdministrator: 'Continuum administrator',
    dataProductOwner: 'Data product owner',
    verticalDeployer: 'Vertical deployer',
    aerosUser: 'aerOS user'
})

// The label determines the presence of the route in the sidebar
// Use only the keys from i18n/i18n.ts.sidebar

const routes = [
    {
        path: '/:catchAll(.*)',
        redirect: '/',
        meta: {
            label: false,
            authorized: [
                ROLES.iotServiceDeployer,
                ROLES.domainAdministrator,
                ROLES.continuumAdministrator,
                ROLES.verticalDeployer,
                ROLES.dataProductOwner,
                ROLES.aerosUser
            ]
        }
    },
    {
        path: '/',
        component: HomeComponent,
        meta: {
            label: 'sidebar.home',
            authorized: [
                ROLES.iotServiceDeployer,
                ROLES.domainAdministrator,
                ROLES.continuumAdministrator,
                ROLES.verticalDeployer,
                ROLES.dataProductOwner,
                ROLES.aerosUser
            ]
        }
    },
    {
        path: '/domains',
        name: 'domains' ,
        component: DomainsComponent,
        meta: {
            label: 'sidebar.domains',
            authorized: [
                ROLES.iotServiceDeployer,
                ROLES.domainAdministrator,
                ROLES.continuumAdministrator,
                ROLES.verticalDeployer,
                ROLES.dataProductOwner
            ]
        },
        children: [
            {
                path: 'domain/:id',
                component: DomainDetail,
                props: true
            }
        ]
    },
    {   path: '/deployments',
        name: 'deployments',
        component: DeploymentsComponent,
        meta: {
            label: 'sidebar.deployments',
            authorized: [
                ROLES.iotServiceDeployer,
                ROLES.domainAdministrator,
                ROLES.continuumAdministrator,
                ROLES.verticalDeployer,
                ROLES.dataProductOwner,
                ROLES.aerosUser
            ]
        },
        children: [
            {
                path: 'new',
                name: 'new-deployment',
                component: NewDeploymentComponent
            },
            {
                path: 'deployment/:id',
                component: DeploymentDetailComponent,
                props: true
            }
        ]
        },
    {
        path: '/continuum',
        name: 'continuum' ,
        component: ContinuumComponent,
        meta: {
            label: 'Continuum',
            authorized: [
                ROLES.domainAdministrator,
                ROLES.continuumAdministrator,
                ROLES.dataProductOwner,
                ROLES.verticalDeployer
            ]
        }
    },
    {
        path: '/benchmark',
        name: 'benchmark',
        component: BenchmarkingToolComponent,
        meta: {
            label: 'Benchmarking',
            authorized: [
                ROLES.domainAdministrator,
                ROLES.continuumAdministrator,
                ROLES.dataProductOwner,
                ROLES.verticalDeployer
            ]
        }
    },
    {
        path: '/notifications',
        name: 'notifications' ,
        component: NotificationsComponent,
        meta: {
            label: 'sidebar.notifications',
            authorized: [
                ROLES.iotServiceDeployer,
                ROLES.domainAdministrator,
                ROLES.continuumAdministrator,
                ROLES.verticalDeployer,
                ROLES.dataProductOwner,
                ROLES.aerosUser
            ]
        }
    },
    {
        path: '/users',
        name: 'users',
        component: UsersComponent,
        meta: {
            label: 'sidebar.users',
            authorized: [
                ROLES.domainAdministrator,
                ROLES.continuumAdministrator
            ]
        }
    },
    {
        path: '/settings',
        name: 'settings',
        component: SettingsComponent,
        meta: {
            label: false,
            authorized: [
                ROLES.iotServiceDeployer,
                ROLES.domainAdministrator,
                ROLES.continuumAdministrator,
                ROLES.verticalDeployer,
                ROLES.dataProductOwner,
                ROLES.aerosUser
            ]
        }
    }
]

const routerInit = VueRouter.createRouter({
    history: VueRouter.createWebHashHistory(),
    routes
})

// Prevent user to access on non authorized routes
routerInit.beforeEach((to, from) => {
    const user = KeycloakService.User()
    const authorized: any = to.meta.authorized
    return user?.realm_access?.roles?.some(r => authorized.includes(r))
})

const router = {
    initialization: routerInit,
    routes: routes
}

export default router

