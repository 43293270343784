import {defineStore} from "pinia";

interface Settings {
    colorScheme: string; // "dark" or "light"
    fontSize: string;
    language: string;
    policy: string;
    notification: any;
}

/**
 * Settings store
 */
export const useSettings = defineStore('useSettings', {
    state: () => {
        return {
            colorScheme: localStorage.getItem("colorScheme"),
            fontSize: localStorage.getItem('fontSize') || "16",
            language:  localStorage.getItem('i18n') || 'en',
            policy: "Lorem ipsum dolor sit amet consectetur. Cursus nisl amet ut malesuada duis vitae. Tempor purus integer posuere sed lorem. Pellentesque tellus quis euismod hendrerit massa. Fames lobortis tempor id proin. Purus urna eu quis sapien. Ac risus imperdiet lorem amet nulla dignissim cursus. Tempor pellentesque tincidunt molestie tempus interdum odio orci sed malesuada. Quam tellus vitae aliquam dui commodo dictum convallis nec. Elit amet integer pharetra hendrerit. Eleifend massa pellentesque tristique cursus. Lacus laoreet sit scelerisque malesuada euismod egestas diam facilisi.",
            notification: Notification.permission
        } as Settings
    }
})