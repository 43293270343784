import {defineStore} from 'pinia'

/**
 * Notification store
 */

interface Notification {
    type: string;
    description: string;
    date: string;
    priority: string;
}

export const useNotificationStore = defineStore('notifications', {
    state: () => {
        return {
            notifications: [] as Notification[]
        }
    },
    actions: {
        addNotification(notification: any) {
            this.notifications.push(notification)
            new Notification(notification, {body: notification})
        },
        removeNotification(index: number) {
            this.notifications.splice(index, 1)
        }
    }
})