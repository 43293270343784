import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, mergeProps as _mergeProps, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-2642ac08"),n=n(),_popScopeId(),n)
const _hoisted_1 = { style: {"display":"grid","align-self":"start","width":"calc(100vw - 200px)","padding":"20px"} }
const _hoisted_2 = { style: {"display":"flex","justify-content":"space-between","padding":"15px 0px 55px 0"} }
const _hoisted_3 = { style: {"height":"calc(100vh - 146px)"} }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_edge_label = _resolveComponent("v-edge-label")!
  const _component_v_network_graph = _resolveComponent("v-network-graph")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("h2", {
        class: _normalizeClass(_ctx.settingsStore.colorScheme === 'dark' ? 'dark' : 'light')
      }, "Continuum", 2)
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createVNode(_component_v_network_graph, {
        ref: "graph",
        class: "graph",
        nodes: _ctx.nodes,
        edges: _ctx.edges,
        layouts: _ctx.layouts,
        configs: _ctx.initialConfigs
      }, {
        "edge-label": _withCtx(({ edge, ...slotProps }) => [
          _createVNode(_component_v_edge_label, _mergeProps({
            text: edge.label,
            align: "center",
            "vertical-align": "above"
          }, slotProps), null, 16, ["text"])
        ]),
        _: 1
      }, 8, ["nodes", "edges", "layouts", "configs"])
    ])
  ]))
}