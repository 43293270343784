<template>
  <div :style="{'font-size': `${settingsStore.fontSize}px`}" class="sidebar" :class="settingsStore.colorScheme === 'dark' ? 'dark' : 'light'">
    <SideBar @logout="logout" :user="user"></SideBar>
  </div>
  <div :style="{'font-size': `${settingsStore.fontSize}px`}" class="view" :class="settingsStore.colorScheme === 'dark' ? 'dark' : 'light'" v-show="!loaderStore.loading">
    <router-view>
    </router-view>
  </div>
  <div :style="{'font-size': `${settingsStore.fontSize}px`}" class="view" :class="settingsStore.colorScheme === 'dark' ? 'dark' : 'light'" v-show="loaderStore.loading">
    <VueSpinner size="40" color="#004AAD"></VueSpinner>
  </div>
  <ModalComponent />
  <ToastsComponent />
</template>

<script lang="ts">
import {defineComponent, onBeforeMount, watch} from 'vue';
import SideBar from "@/components/SideBar.vue";
import ModalComponent from "@/components/ModalComponent.vue";
import ToastsComponent from "@/components/ToastsComponent.vue";
import AuthenticationService from "@/services/AuthenticationService";
import {useModalStore} from "@/stores/modal";
import {useLoaderStore} from "@/stores/loader";
import {VueSpinner} from "vue3-spinners";
import {useSettings} from "@/stores/settings";
import {useNotificationStore} from "@/stores/notifications";
import {useToastsStore} from "@/stores/toasts";

export default defineComponent({
  name: 'App',
  components: {
    SideBar,
    ModalComponent,
    ToastsComponent,
    VueSpinner
  },
  setup: () => {
    const modalStore = useModalStore()
    const loaderStore = useLoaderStore()
    const settingsStore = useSettings()
    const notificationStore = useNotificationStore()
    const toastStore = useToastsStore()
    onBeforeMount(() => {
      // domainStore.fetchDomains()
      loaderStore.register()
    })
    return {
      modalStore,
      loaderStore,
      settingsStore
    }
  },
  data: function () {
    return {
      user: AuthenticationService.User()
    }
  },
  methods: {
    logout() {
      return AuthenticationService.CallLogout()
    }
  }
});
</script>

<style>

@import url('https://fonts.googleapis.com/css?family=Poppins');
body {
  margin: 0;
}

/* Firefox (uncomment to work in Firefox, although other properties will not work!)  */
/** {
  scrollbar-width: thin;
  scrollbar-color: #004AAD #FFF4F4;
}*/

/* Chrome, Edge and Safari */
/**::-webkit-scrollbar {*/
/*  height: 10px;*/
/*  width: 10px;*/
/*}*/
/**::-webkit-scrollbar-track {*/
/*  border-radius: 10px;*/
/*  background-color: transparent;*/
/*}*/

/**::-webkit-scrollbar-track:hover {*/
/*  background-color: transparent;*/
/*}*/

/**::-webkit-scrollbar-track:active {*/
/*  background-color: transparent;*/
/*}*/

/**::-webkit-scrollbar-thumb {*/
/*  border-radius: 10px;*/
/*  background-color: #004AAD;*/
/*}*/

/**::-webkit-scrollbar-thumb:hover {*/
/*  background-color: #004AAD;*/
/*}*/

/**::-webkit-scrollbar-thumb:active {*/
/*  background-color: #004AAD;*/
/*}*/

#app {
  font-family: Poppins;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #004AAD;
  display: grid;
  grid-template-areas: "sidebar view";
  grid-template-columns: 200px auto;
  grid-auto-rows: max-content;
}

.container {
  display: grid;
  grid-template-rows: 108px auto 108px auto;
  width: calc(100vw - 200px);
  height: 100vh;
  align-items: start;
  padding: 20px;
}

.btn-new {
  border-radius: 20px;
  background: #004AAD;
  color: #FFFFFF;
  min-width: 240px;
  height: 30px;
  padding: 3px;
  font-family: Poppins;
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: space-around;
  align-self: center;
  cursor: pointer;
  font-size: 14px;
  font-weight: bold;
  transition: opacity 0.2s ease-in-out;
}

.btn-new:hover {
  opacity: 0.7;
}

.login {
  display: grid;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
}

.sidebar {
  grid-area: sidebar;
  position: relative;
  height: 100vh;
  box-shadow: 1px 0px 4px 0px rgba(84,82,82,0.37);
  -webkit-box-shadow: 1px 0px 4px 0px rgba(84,82,82,0.37);
  -moz-box-shadow: 1px 0px 4px 0px rgba(84,82,82,0.37);
}

.view {
  grid-area: view;
  display: inline-grid;
  justify-content: center;
  align-items: center;
  max-height: 100vh;
  overflow: auto;
}

#app, .sidebar, .view {
  transition: background-color 0.3s linear;
}

.dark {
  background-color: #0F0F0F;
  color: #E6EAF6 !important;
}

h2.dark, h4.dark {
  background-color: transparent;
  color: #E6EAF6 !important;
}

.light {
  background-color:#ffffff;
}

h2.light, h4.light {
  background-color: transparent;
}

</style>
