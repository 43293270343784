<template>
  <div v-show="$route.name === 'deployments'" class="container">
    <div style="display: flex; justify-content: space-between; padding: 15px 0px 55px 0">
      <h2 :class="settingsStore.colorScheme === 'dark' ? 'dark' : 'light'">{{$t('deployments.deployedServices')}}</h2>
      <router-link :to="!hasError ? '/deployments/new' : ''" :class="{'disabled': hasError}" class="btn-new"><font-awesome-icon style="font-size: 16px" icon="circle-plus" /> {{$t('deployments.newServiceDeployment')}} <span></span></router-link>
    </div>
    <div class="deployments">
      <DataTable v-if="!hasError" :data="deployments.tableData"></DataTable>
      <ErrorComponent style="justify-self: center" v-if="hasError" @reload="async () => await fetchDeployments()" />
    </div>
  </div>
  <router-view></router-view>
</template>

<script lang="ts">
import {defineComponent, onMounted, reactive, ref} from "vue";
import DataTable from "@/shared/DataTable/DataTable.vue";
import ErrorComponent from "@/shared/ErrorComponent.vue";
import {useDeploymentsStore} from "@/stores/deployment";
import {onBeforeRouteUpdate, useRouter} from "vue-router";
import {useModalStore} from "@/stores/modal";
import {useToastsStore} from "@/stores/toasts";
import {useSettings} from "@/stores/settings";

interface Deployment {
  _id: string;
  name: string;
  description: string;
  tbd: string;
  actions: Array<string>
}

export default defineComponent({
  name: "DeploymentsComponent",
  components: {DataTable, ErrorComponent},
  setup: () => {
    const deploymentsStore = useDeploymentsStore()
    const modalStore = useModalStore()
    const toastStore = useToastsStore()
    const settingsStore = useSettings()
    const hasError = ref(false)
    const router = useRouter()
    const deployments = reactive({
      tableData: {
        tableConfig: {
          style: {
            columnWidth: ['10%', '25%','45%', '10%']
          }
        },
        sortKey: '',
        columns: [
          {label: 'ID', value: 'id', style: {'width': '10%'}},
          {label: 'deployments.name', value: 'name', style: {'width': '25%'}},
          {label: 'deployments.description', value: 'description', style: {'width': '45%'}},
          {label: 'deployments.actions', value: 'actions', hasActions: true,style: {'width': '10%'}}],
        rows: null as unknown as Deployment[]
      }
    })

    /**
     * Fetch data from deployment service
     */
    const fetchDeployments = async () => {
      deployments.tableData.rows = await deploymentsStore.fetchDeployments()
          .then((res) => {
            res.data.map((d: any) => {
              d.id = d.id.split(":Service:")[1]
              d.actions = [
                {
                  icon: 'eye',
                  onClick: () => {
                    router.push(`/deployments/deployment/${d.id}`)
                  },
                },
                {
                  icon: 'trash',
                  onClick: () => {
                    modalStore.showModal({template: "delete", data: d})
                  }
                }
              ]
            })
            hasError.value = false
            return res.data
          })
          .catch(err => {
            hasError.value = true
            console.error(err)
            toastStore.appendToast(
                {
                  label: `Looks like something went wrong: ${err?.message}`,
                  success: false,
                  data: {},
                  autoRemove: false
                })
          })
    }
    onMounted(async () => {
      if (router.currentRoute.value.name === 'deployments') {
        await fetchDeployments()
      }
    })
    onBeforeRouteUpdate(async (to, from) => {
      // Update data on return to deployments after service creation
      if (from.path.split("/").includes("new") ||
          from.path.split("/").includes("deployment")) {
        await fetchDeployments()
      }
    })
    return {
      deployments,
      hasError,
      settingsStore,
      fetchDeployments
    }
  }
})
</script>

<style scoped>

h2 {
  color: #004AAD;
}

.disabled {
  opacity: 0.7;
  pointer-events: none;
}

.deployments {
  justify-self: center;
  align-self: start;
}

.new-deployment {
  border-radius: 20px;
  background: #004AAD;
  color: #FFFFFF;
  min-width: 240px;
  height: 30px;
  padding: 3px;
  font-family: Poppins;
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: space-around;
  align-self: center;
  cursor: pointer;
  font-size: 14px;
  font-weight: bold;
  transition: opacity 0.2s ease-in-out;
}

.new-deployment:hover {
  opacity: 0.7;
}

</style>